import React, { useMemo } from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

interface I_ImageProps {
  src: string;
  alt?: string;
}

interface I_NodeProps {
  childImageSharp: any;
  publicURL: string;
  extension: string;
  relativePath: string;
}

const Image: React.FC<I_ImageProps> = ({ src, ...rest }: I_ImageProps): JSX.Element | null => {
  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: { internal: { mediaType: { regex: "/image/" } } }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `);

  const match = useMemo(() => data?.images?.edges?.find(({ node }: {node: I_NodeProps}) => {
    return src === node?.relativePath;
  }), [data, src]);

  if (!match) return null;

  const { childImageSharp, publicURL, extension }: I_NodeProps = (match?.node || {});

  if (extension === "svg" || !childImageSharp) {
    return <img src={publicURL} {...rest} />;
  }

  return <Img fluid={childImageSharp.fluid} {...rest} />;
};

export default Image;